<template>
  <div>
    <div class="banner">
      <img src="@/assets/img/refer/banner.png" alt="" />
    </div>
    <div class="container">
      <div class="page-title">
        “北美内推计划”免费内推
        <br />
        Intern &amp; New Grad &amp; 在职跳槽
      </div>
      <div class="section-title">【我的初衷】</div>
      <div class="section-content">
        <p>大家好，我是Edward。</p>
        <p>
          做这个网站已经三年有余，其实总是想为我们留学生群体做更多的工作，但无论是中美的关系，还是疫情全球形式的影响，都让我们的留学生涯雪上加霜。尤其是政策影响，让我们很多留学生在已经拿到
          offer的情况下，无法来到美国。其实在这种全球大势的情况下，有时候感觉很深深的无奈，我总想去凭着自己的努力去改变什么，想着自己能去做些什么，但个人的力量太渺小，根本无力去改变这样的局面，
          我也只能想在自己的领域，能尽一份力尽一份力。
        </p>
        <p>
          想来想去，好像自己也只能做些力所能及的助力。CSON建立三年，中间波折困难真的很多，我不知道这个网站能做到什么时候，疫情以来，
          已经亏损一年半多，但今年河南发水，有些企业也是一样处于亏损仍然捐赠千万，其实给我很大触动。那时候我就想，即使有一天不行了，但我也想把我所有的想法努力都尝试一次，至少求个无怨无悔。
        </p>
        <p>
          所以打算认真做这件事情，想试试，试试能不能把"内推"这件事情做好。内推是我在留学的时候就想尝试的一件事情，当时就想为什么海外的中国人这么多，大家不能像三哥三姐一样那么团结互助，所以
          我想试试能不能凭一己之力做好这件事情，即使失败了我也想尝试一下，至少我要努力一次。
        </p>
        <p>
          其实之前很多国人做过内推平台，但最后都失败了。我认为最大的原因就是内推无效化太多，我理解留学生刚来美国着急，着急去找实习全职，但很多人题都没刷几道就急着去找人推，最后的结果就是面一个挂一个。
          很多国人同胞一开始也是抱着帮助同胞的想法去帮忙推自己的公司，但最后100个人有的是没一个过的，失望大于期望，而且这样对自己在公司的影响也是不好的，所以慢慢就不愿意推了。
        </p>
        <p>
          当年我留学的时候，刷题过千，很多人是看着我的视频拿到Google，facebook的offer的，感觉自己空有屠龙之术，连个表现的机会都没有。所以我想，给那些有实力有水平的同学们，一次机会，刷题不够
          水平还欠缺的同学，好好努力提升水平，我们随时欢迎。同时，也给那些已经上岸的同胞们一些信心，不是推一个不成一个，我们做的事情，无论是成功还是失败，都是有意义的。
        </p>
        <p>
          算了一下，三年多，联系了一些想推的曾经的学生，朋友，合作伙伴，大概能推40+公司，主流的Google，facebook，Amazon，Microsoft，Uber，北美字节，VMware，PayPal，eBay等等这些公司，
          我都有资源人脉，这还是在我没有扩大范围问其他愿意推的人的情况下。所以有这么多的资源，我真的想试试，为我们的同胞做一些事情，看看，到底行不行。
          如果有在公司上班的兄弟姐妹想加入我们的内推Family，随时和我联系。
        </p>
        <p>
          希望大家能够支持一下，多告诉一些身边的人，如果是学生，多推荐一下，如果是上班的，联系我，我们一起做起来！
        </p>
      </div>
      <div class="section-title">【内推流程】</div>
      <div class="section-content">
        <p>
          1、添加微信 - 自我介绍 + 转发，简单介绍一下自己，找实习全职等基本情况
        </p>
        <p>
          2、上传资料 -
          Resume，Email，想推公司，不推公司（已经拿到面试或已推公司）等
        </p>
        <p>3、内推 - 联系内推人，如果有人愿意推，会发邮件等方式联系学生</p>
      </div>
      <div class="section-title">【内推细节】</div>
      <div class="section-content">
        <p>
          1、内推公司数量，取决于自己的简历和刷题水平实力情况，优秀的肯定会得到很多内推人青睐，我们尽量去保证每个学生得到公司的内推机会，但内推与否是取决于内推人，如果优秀，<span
            style="color: rgba(250, 100, 0, 1)"
          >
            「10家」</span
          >起步
        </p>
        <p>
          2、留学生实力水平，我们希望推的人是有成功率的，水平不行推了也是挂，就没有意义了。所以会有一定的门槛
        </p>
        <p>
          3、全程自愿原则，不会强迫也不会泄露个人任何信息，网站成立三年以来没有在信息安全出过问题，所有信息均在阿里云存储，请大家放心
        </p>
        <p>
          4、因公司数量和内推人数量有限，请大家积极报名，<span
            style="color: rgba(250, 100, 0, 1)"
            >先到先得</span
          >
        </p>
        <div class="section-title">【内推人】</div>
        <div class="section-content">
          <p>
            如果有公司的在职同胞想加入内推计划推学生，尽一份力，随时联系我，不要有自己公司不是大厂等毫无意义的担心，只要有这个心，就是好样的
          </p>
          <p>个人微信号：cspiration</p>
        </div>
        <div class="section-title">【活动时间】</div>
        <div class="section-content">
          <p>2021.9.13 ~ 2021.10.13</p>
        </div>
        <p style="color: rgba(255, 59, 48, 1)">
          我在此郑重承诺，活动期间，内推计划全程免费
        </p>
      </div>

      <div class="interviewButton">
        <div class="ibContainer" @click="getInterview">立即获得内推</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "Refer",
  data() {
    return {};
  },
  methods: {
    getInterview() {
      Toast("请电脑端访问cspiration.com填写相关信息");
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100;
  img {
    width: 100%;
  }
}
.container {
  width: calc(100% - 20px);
  padding: 20px 10px;
}
.page-title {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
}
.create-time {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 18px;
  margin-top: 16px;
}
.section-title {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #fa6400;
  line-height: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: underline;
  text-decoration-color: rgba(250, 100, 0, 0.2);
}
.section-content p {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 18px;
  margin-bottom: 16px;
  line-height: 24px;
}
.ibContainer {
  width: 335px;
  height: 45px;
  background: linear-gradient(129deg, #fd1d1d 0%, #fcb045 100%);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  line-height: 45px;
}
</style>